import { createRouter, createWebHistory } from "vue-router";
//import Dashboard from '../views/DashboardView.vue';
//import Nprogress from "nprogress";
//import "nprogress/nprogress.css";
//import Login from "../views/Login.vue";
//import Auth from "../services/Auth";



const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView"),
  },
  {
    path: "/dashboard",
    component: () => import("../views/DashboardView"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "/mensajeria",
        component: () => import("../views/MensajeriaView"),
      },
      {
        path: "/campanas",
        component: () => import("../views/CampanasView"),
      },
      {
        path: "/usuarios",
        component: () => import("../views/UsuariosView"),
      },
      {
        path: "/cargas",
        component: () => import("../views/CargasView"),
      },
      {
        path: "/reportes",
        component: () => import("../views/ReportesView"),
      },
      {
        path: "/api_keys",
        component: () => import("../views/ApiView"),
      },
      {
        path: "/diccionarios",
        component: () => import("../views/DiccionariosView"),
      },
      {
        path: "/validador",
        component: () => import("../views/ValidadorView"),
      },
      {
        path: "/empresas",
        component: () => import("../views/EmpresasView"),
      },
      {
        path: "/numeros",
        component: () => import("../views/NumerosView"),
      },
      {
        path: "/regionalizacion",
        component: () => import("../views/RegionalizacionView"),
      },
    ],
  },
  {
    path: "/programacion",
    name: "",
    component: () => import("../views/ProgramacionView"),
  },
  {
    path: "/embeded",
    name: "embeded",
    component: () => import("../views/EmbedView"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


export default router;
