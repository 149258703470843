import { createApp, reactive } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "@/config/axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import $ from "jquery";
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Vue3Toasity from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "@fortawesome/fontawesome-free/js/all";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import VueQrcode from 'vue-qrcode';
import config from '../config';
import 'select2/dist/css/select2.css';
import 'select2/dist/js/select2.full.js';
import eventBus from './config/eventBus';

/**
 * OTRAS LIBRERIAS NECESARIAS
 */
import VNetworkGraph from 'v-network-graph'
import 'v-network-graph/lib/style.css'


import clickOutside from './directives/click-outside';

// Configuración de jQuery como una variable global
window.$ = $;
window.jQuery = $;

/**
 * WEBSOCKET
 */
let urlWs = "";
const dev = config.APP_DEV;
if (dev) {
  urlWs = config.WEBSOCKET_DEV;
} else {
  urlWs = config.WEBSOCKET_PROD;
}

const webSocket = new WebSocket(urlWs);
webSocket.onopen = () => {
  console.log('WebSocket is connected.');
};

webSocket.onclose = () => {
  console.log('WebSocket is disconnected.');
};

webSocket.onerror = (error) => {
  console.log('WebSocket error:', error);
};

webSocket.onmessage = (event) => {
  const data = JSON.parse(event.data);
  eventBus.emit(data.event, data);
};



// Token Reactivo
const token = reactive({
  value: localStorage.getItem("token"),
});



// nro Reactivo
const nro_telefono = reactive({
  value: localStorage.getItem("nro_telefono"),
});



// Configurar el interceptor para actualizar el token automáticamente
axios.interceptors.request.use((config) => {
  if (token.value) {
    config.headers.Authorization = `Bearer ${token.value}`;
  }
  return config;
});

const app = createApp(App)
  .use(store)
  .use(router)
  .use(config)
  .use(Vue3Toasity)
  .use(LoadingPlugin)
  .use(VNetworkGraph)
  
  .use(VueSweetalert2);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$token = token;
app.config.globalProperties.$nro_telefono = nro_telefono;
app.config.globalProperties.$Swal = VueSweetalert2;
app.config.globalProperties.$webSocket = webSocket;
app.config.globalProperties.$eventBus = eventBus;
app.component('qrcode', VueQrcode);
app.directive('click-outside', clickOutside);
app.mount("#app");