import http from "../config/axios";
//import config from '../../config';


class Message {

  instance(data) {
    return http.post(`/messages/instance`, data);
  }

  getQr(data) {
    return http.post(`/messages/getQr`, data);
  }
  sendMessage(data) {
    return http.post(`/messages/sendmessage`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
  conversaciones(data) {
    return http.post(`/messages/conversaciones`, data);
  }
  listaMensajes(data) {
    return http.post(`/messages/listaMensajes`, data);
  }
  stopInstance(data) {
    return http.post(`/messages/stopInstance`, data);
  }
  detalleMensajeria(data) {
    return http.post(`/instancia/detalleMensajeria`, data);
  }

  envioInstancia(data) {
    return http.post(`/instancia/send`, data);
  }
}

export default new Message();