<template>
  <div>
    <transition :duration="1950" name="slide-fade">
    <router-view />
    </transition>
    <Footer v-if="!embed" />
  </div>


</template>

<script>
import Footer from '@/components/Footer';
//import PruebaWebPhone from '@/components/PruebaWebPhone';
//import  '@/assets/js/custom.js';
import { useRoute } from 'vue-router';
import Message from "./services/Message";

export default {
  components: {
    Footer,

  },
  setup() {
    const route = useRoute();
    return {
      route
    };
  },
  data() {
    return {
      app: true,
      nro_telefono: window.localStorage.getItem("nro_telefono"),
      id: window.localStorage.getItem("id"),
      embed: true
    };
  },
  methods: {
    // Métodos de tu componente, si es necesario
  },
  created() { 


  },
  mounted() {
    let loader = this.$loading.show();
  this.$nextTick(() => {
    // Pausa la ejecución por 2 segundos (2000 ms)
    setTimeout(() => {
      const path = this.$route.path;
      console.log("Ruta actual después de 2 segundos:", path);

      if (path !== "/embeded") {
        this.embed = false;

        const data = {
          nro_telefono: this.nro_telefono,
          id: this.id
        };

        if (this.nro_telefono && this.id) {
          Message.instance(data)
            .then(response => {
              console.log("Respuesta del servidor:", response);
              // Maneja la respuesta aquí
            })
            .catch(error => {
              localStorage.clear();
              this.$router.push('/login');
              console.error("Error:", error);
            });

            loader.hide();
        } else {
          this.$router.push('/login');
          loader.hide();
        }
      }else{
        loader.hide();
      }
    }, 2000); // 2000 ms = 2 segundos
  });
},
  beforeUnmount() {
    // Accede a la instancia de WebSocket
    const webSocket = this.$webSocket;

    // Verifica si la conexión WebSocket está abierta
    if (webSocket.readyState === WebSocket.OPEN) {
      // Cierra la conexión WebSocket
      webSocket.close();
    }
  }
};


</script>
<style>
@import "./assets/css/style.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
