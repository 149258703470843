import { createStore } from 'vuex';

export default createStore({
  state() {
    return {
      token: localStorage.getItem('token') || '',
      client : "load",
      nro_telefono: ""
    };
  },
  getters: {
    getToken: state => state.token,
    getTClient: state => state.client,
    getTNroTelefono: state => state.nro_telefono,
  },
  mutations: {
    setToken(state, token){
      state.token = token;
    },
    setClient(state, client){
      state.client = client;
    },
    sertNroTelefono(state, nro_telefono){
      state.nro_telefono = nro_telefono;
    }
   
  },
  actions: {
    updateToken({ commit}, token){
      commit('setToken',token);
      
    },
    updateClient({ commit}, client){
      commit('setClient',client);
      
    },
    updateNroTelefono({ commit}, nro_telefono){
      commit('sertNroTelefono',nro_telefono);
      
    },
  },
  modules: {
  }
})
